.slideCountdown {
  display: inline-block;
  font-family: Courier;
  font-weight: bold;
}

.slide-block-time {
  float: left;
  font-size: 40px;
  width: 70px;
  margin: 0 15px 0 15px;


  >.title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
  }

  .wrap-stage {
    >.stage {
      float: left;
      width: 50%;
      overflow: hidden;
      height: 40px;
      position: relative;

      >.mask {
        width: 100%;
        position: absolute;
        bottom: 0%;
        left: 0px;
        height: 80px;

        >div {
          height: 40px;
          line-height: 40px;
        }
      }
    }
  }
}
